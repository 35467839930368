import Marquee3k from "marquee3000";

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  function handleDOMContentLoaded() {
    Marquee3k.init();
  }
})();
